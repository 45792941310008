export default {
  "routes": {
    "collection-dates": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tømming"])}
    },
    "collection-history": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tømmehistorikk"])}
    },
    "debug-page": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debug"])}
    },
    "error-page": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
    },
    "login-screen": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg inn"])}
    },
    "logout-callback": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg ut callback"])}
    },
    "my-page": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min Side"])}
    },
    "not-found": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke funnet"])}
    },
    "oidcCallback": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg inn callback"])}
    },
    "recycling-station-qr-code": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-kode for adgang til gjenvinningsstasjoner"])}
    },
    "resend-qrcode": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send QR-kode på nytt"])}
    },
    "recycling-admin-users": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgang delt med andre"])}
    },
    "recycling-admin-vehicles": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kjøretøy med automatisk adgang til gjenvinningsstasjoner"])}
    },
    "recycling-visits": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis besøk"])}
    },
    "select-property": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg Eiendom"])}
    },
    "terms-consent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtykke"])}
    }
  },
  "global": {
    "strings": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbake"])},
      "back-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå tilbake til hovedsiden"])},
      "back-to-login-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå tilbake til innloggingssiden"])},
      "click-here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klikk her for å få tilsendt koden på nytt"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laster..."])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg inn"])},
      "login-as-customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg inn som kunde"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg ut"])},
      "select-property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg eiendom"])},
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velkommen"])}
    },
    "texts": {
      "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["&copy; Avfall Sør AS - Kode og design <a href=\"https://aptum.no\" target=\"_blank\">Aptum</a> &vert; v", _interpolate(_named("appVersion"))])},
      "terms-of-use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<a href=\"https://avfallsor.no/administrative-retningslinjer/\">Brukervilkår / retningslinjer</a>"])},
      "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<a href=\"https://avfallsor.no/personvernerklaering/\">Personvernerklæring og bruk av cookies</a>"])},
      "widget-2-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besøksadresse"])},
      "widget-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Vige havnevei 90 - 2.etasje<br>4689 Kristiansand</p>"])},
      "widget-3-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postadresse"])},
      "widget-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Postboks 4094<br>4689 Kristiansand</p>"])},
      "widget-4-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundesenter"])},
      "widget-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Mandag-fredag:<br>08:00 - 15:30<br><a href=\"tel:38177070\">38 17 70 70</a><br><a href=\"mailto:post&commat;avfallsor.no\">post&commat;avfallsor.no</a></p>"])},
      "widget-5-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Følg oss på sosiale medier"])}
    },
    "forms": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt"])},
      "confirmation-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker?"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett"])},
      "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornavn"])},
      "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etternavn"])},
      "invalid-field": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " er ugyldig"])},
      "phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
      "phone-number-no-country-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer (8 siffer)"])},
      "required-field": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " er påkrevd"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
      "required-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felt merket med \"<span class=\"form-required\">*</span>\" er obligatoriske felt og må fylles ut."])}
    }
  },
  "view": {
    "admin-access": {
      "active-access-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive adganger:"])},
      "add-access-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Del adgang:"])},
      "add-access-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fyll inn personopplysningene til den du vil dele adgang med."])},
      "form-submit-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Del adgang til gjenvinningsstasjonene"])},
      "form-submit-value-unauthenticated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du må logge inn for å dele adgang til gjenvinningsstasjonene"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgang delt med andre"])},
      "pending-access-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adganger som venter godkjenning:"])},
      "top-html": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Som kunde hos Avfall Sør kan du dele adgang til gjenvinningsstasjonene med andre. Den som blir tildelt adgang mottar adgangsbevis på SMS med en unik QR-kode. Adgangen er aktiv frem til den slettes av deg eller den du har delt adgang med. Besøkene på gjenvinningsstasjonene registreres på din eiendom."])}
    },
    "admin-vehicles": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kjøretøy med automatisk adgang til gjenvinningsstasjoner"])},
      "main-html": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrering av skiltnummer på kjøretøy er et enkelt og effektivt adgangsbevis for våre gjenvinningsstasjoner. Ved bruk av automatisk skiltgjenkjenning blir kunder identifisert ved innkjøring. Bommen åpnes automatisk."])},
      "active-vehicles-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive registreringsnummer:"])},
      "add-vehicles-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til nytt registreringsnummer:"])},
      "add-vehicles-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ved å legge til et registreringsnummer gir du bilen tilgang til gjenvinningsstasjonene."])},
      "form-registration-number-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreringsnummer"])},
      "form-submit-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til registreringsnummer"])},
      "form-submit-value-unauthenticated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du må logge inn for å legge til registreringsnummer"])}
    },
    "collection": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hentedager"])},
      "sub-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dine kommende hentedager:"])},
      "main-html": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h3>Ved henting av avfall</h3><p>På tømmedag må dunken(e) være satt ut til vei- eller fortauskant ved kjørbar vei senest kl. 06.00. Dersom veien ikke er kjørbar grunnet snø eller andre hindringer, kan renovatøren unnlate å tømme dunken(e). Husk at gul pil må peke ut mot veien.</p><h3>Avvik ved offentlige fridager</h3><p>På offentlige fridager hentes avfallet på andre dager enn normalt. Merk at renovatørene våre kjører doble skift, dunkene kan derfor bli tømt senere enn vanlig.</p>"])},
      "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis flere hentedager"])}
    },
    "collection-history": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din tømmehistorikk %year%"])}
    },
    "property-consent": {
      "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godtatt"])},
      "button-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksepter"])},
      "checkbox-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja - jeg samtykker til Avfall Sørs personvernerklæring"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personvern i Avfall Sør"])},
      "main-html": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Når du bruker våre tjenester, medfører dette at Avfall Sør innhenter, behandler og lagrer ulike personopplysninger om deg. Du kan lese mer om hvordan Avfall Sør behandler personopplysningene dine i vår <a href=\"https://avfallsor.no/personvernerklaering/\" target=\"_blank\">personvernerklæring</a>.</p>"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke godtatt"])}
    },
    "qrcode-display": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivt adgangsbevis"])},
      "main-html": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>QR-kode er et enkelt digitalt adgangsbevis for våre gjenvinningsstasjoner.<br>Koden fremvises og skannes i selvbetjeningsautomat ved innkjøring.<br>Bommen åpnes automatisk.</p>"])}
    },
    "qrcode-resending": {
      "main-html": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p><b>Har du fått tildelt adgangsbevis, men finner ikke QR-koden?</b><br>Tast inn ditt mobilnummer så sender vi QR-koden på nytt</p>"])},
      "bottom-html": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>For å få tilgang til Min side samt adgang til våre gjenvinningsstasjoner, må du være kunde hos Avfall Sør.<br>Våre kunder kan også dele adgang til gjenvinningsstasjonene med andre.<br>Dersom du har fått tildelt adgangsbevis skal du ha mottatt SMS med en unik QR-kode.<br>Adgangen er aktiv frem til den slettes av deg eller kunden.</p><p><b>Du vil ikke motta SMS dersom tildelt adgang er inaktivert/slettet.</b></p>"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send QR-kode på nytt"])}
    },
    "select-property": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg eiendom"])},
      "main-html": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p><strong>Beklager!</strong></p><p>Du har ikke tilgang til Min side. Det kan være ulike årsaker til dette:<ul><li>Du er ikke registrert som kunde hos Avfall Sør og tinglyst eier av en eiendom i Kristiansand eller Vennesla</li><li>Hvis du nettopp har flyttet, kan det være forsinkelser i systemet</li><li>Representerer du en virksomhet eller organisasjon? Sjekk og hent rolle fra Altinn nedenfor.</li><li>Det kan være en teknisk feil</li></ul></p><p>Ta kontakt med <a href=\"https://avfallsor.no/kontakt/kundesenter/\">kundesenter</a> hvis du er usikker på hvorfor du ikke får tilgang</p>"])}
    },
    "station-qr-code": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-kode for adgang til gjenvinningsstasjoner"])},
      "main-html": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-kode er et enkelt digitalt adgangsbevis for våre gjenvinningsstasjoner. Koden fremvises og skannes i selvbetjeningsautomat ved innkjøring. Bommen åpnes automatisk."])},
      "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last ned QR-kode"])}
    },
    "terms-consent": {
      "button-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksepter og gå videre"])},
      "checkbox-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg godkjenner brukervilkår og personvernerklæring"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtykke av brukervilkår"])},
      "main-html": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Min side er vår innloggingstjeneste der du har muligheten til å administrere og overvåke ditt kundeforhold med Avfall Sør. Ved å logge deg inn på tjenesten og benytte deg av Min side aksepteres følgende vilkår:</p><p>Du er ansvarlig for, og godkjenner at de opplysninger du endrer eller registrerer på Min side om deg selv eller andre er korrekte, samt at disse lagres og vises i våre systemer. Du godkjenner at adgangsbevis (QR-kode og/eller skiltgjenkjenning av registreringsnummer på bil) som du oppretter/deler med andre er knyttet til ditt kundeforhold hos Avfall Sør, og statistikk om bruk lagres og oppbevares hos oss. Avfall som leveres til Avfall Sør skal kun være husholdningsavfall fra Avfall Sørs kunder.</p><p>Siste versjon av vår <a href=\"https://avfallsor.no/personvernerklaering/\">personvernerklæring</a> finner du alltid tilgjengelig på våre nettsider. Det er viktig at du leser gjennom denne slik at du vet hvordan Avfall Sør samler inn, lagrer og behandler dine personopplysninger. Ved betydelige endringer i avtale eller brukervilkår/retningslinjer vil vi informere deg om dette. Vi vil også innhente nytt samtykke dersom vi ønsker å bruke dine personopplysninger på annen måte enn det du allerede har samtykket til.</p>"])},
      "bottom-html": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Oppdaterte <a href=\"https://avfallsor.no/administrative-retningslinjer/\">administrative retningslinjer</a> finner du på Avfall Sørs nettside.</p>"])}
    },
    "visits": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besøk på gjenvinningsstasjoner i %year%"])}
    }
  },
  "component": {
    "collections": {
      "log-in-to-see-collection-history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg inn for å se tømmehistorikken"])},
      "log-in-to-see-visit-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg inn for å se gjenvinningsstasjonene"])},
      "no-collection-date-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen hentedag funnet"])},
      "trash": {
        "biowaste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bioavfall"])},
        "glass-metal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glass- og metallemballasje"])},
        "paper-plastic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papp, papir og plastemballasje"])},
        "residual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restavfall"])}
      },
      "weekdays": {
        "monday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["mandag"]), _normalize(["mandager"])])},
        "tuesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["tirsdag"]), _normalize(["tirsdager"])])},
        "wednesday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["onsdag"]), _normalize(["onsdager"])])},
        "thursday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["torsdag"]), _normalize(["torsdager"])])},
        "friday": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["fredag"]), _normalize(["fredager"])])}
      },
      "frequencies": {
        "every-week": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tømmes ", _interpolate(_named("weekday")), " hver uke"])},
        "every-second-week": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tømmes ", _interpolate(_named("weekday")), " hver 14. dag"])},
        "every-fourth-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tømmes hver 4. uke"])},
        "every-eighth-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tømmes hver 8. uke"])}
      }
    },
    "error": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beklager, nå er det rusk i maskineriet"])},
      "main-html": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Prøv igjen om litt, eller gå til <a href=\"/\">forsiden</a></p>"])},
      "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv igjen"])}
    },
    "list": {
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send igjen"])}
    },
    "login": {
      "my-page": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min side"])},
        "main-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min side er en kundeportal for Avfall Sørs abonnenter. Her kan du administrere dine adgangsbevis og ditt øvrige kundeforhold. Flere digitale tjenester kommer etterhvert."])}
      },
      "our-page": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vår side"])},
        "main-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vår side er en internportal for Avfall Sørs ansatte. Her kan vi administrere kundens adgangsbevis og deres øvrige kundeforhold. Flere digitale tjenester kommer etterhvert."])}
      },
      "missing-qrcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tildelt adgangsbevis, men finner ikke QR-koden?"])},
      "form-id-number-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitetsnummer"])}
    },
    "organization-list": {
      "altinn-error-already-exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Beklager!</strong> Denne virksomheten/organisasjonen administreres av deg fra før, og kan ikke legges til på nytt."])},
      "altinn-error-no-relationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Beklager!</strong> Denne virksomheten/organisasjonen har ikke noe kundeforhold til Avfall Sør.<br>Kontakt <a href=\"https://avfallsor.no/kontakt/kundesenter/\" target=\"_blank\">kundesenter</a> hvis dette er feil."])},
      "altinn-no-roles-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Ooops!</strong> Det ble ikke funnet noen roller i Altinn.<br>Kontakt <a href=\"https://avfallsor.no/kontakt/kundesenter/\">kundesenter</a> hvis dette er feil."])},
      "altinn-text-footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "altinn-text-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representerer du en virksomhet/organisasjon som ikke vises her?"])},
      "altinn-text-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjekk og hent rolle fra Altinn"])},
      "entity-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Org.nr."])},
      "altinn-list-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til for å administrere på Min side"])}
    },
    "proof-of-entry": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangsbevis"])},
      "qr-code-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-kode for adgang til gjenvinningsstasjoner:"])},
      "vehicle-access-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kjøretøy med automatisk adgang til gjenvinningsstasjoner:"])},
      "shared-access-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgang delt med andre:"])},
      "visit-overview-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis besøksoversikt"])},
      "main-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundeidentifisering sikrer at de som besøker våre gjenvinningsstasjoner, er de samme som betaler renovasjonsgebyr i Kristiansand og Vennesla. Adgangsbevis som kan brukes: skanning av QR-kode, skiltgjenkjenning på kjøretøy og skanning av nøkkelbrikke. Som kunde kan du også dele adgang til gjenvinningsstasjonene med andre."])}
    },
    "qr-code-list": {
      "confirmation-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sletting av adgang"])},
      "delete-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett adgang"])}
    },
    "station-qr-codes": {
      "no-qr-code-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen QR-kode funnet"])}
    },
    "users": {
      "confirmation-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sletting av bruker"])},
      "no-user-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen bruker funnet"])}
    },
    "vehicles": {
      "no-vehicle-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen kjøretøy funnet"])},
      "confirmation-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sletting av registreringsnummer"])},
      "delete-vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett registreringsnummer"])}
    },
    "waste-management": {
      "collection-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dine hentedager:"])},
      "collection-history-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis tømmehistorikk"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tømmekalender"])},
      "log-in-to-see-calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg inn for å se tømmekalenderen"])},
      "more-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mer informasjon om hentedager"])}
    }
  },
  "layout": {
    
  },
  "api": {
    "getEstatesPerCustomer": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GetEstatesPerCustomer"])}
    },
    "getEmptyingHistory": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GetEmptyingHistory"])}
    },
    "getEmptyingDates": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GetEmptyingDates"])}
    },
    "getQRCode": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GetQRCode"])}
    },
    "getQRCodesByPropertyId": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GetQRCodesByPropertyId"])}
    },
    "getUsageStatistics": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GetUsageStatistics"])}
    },
    "getVehiclesByPropertyId": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GetVehiclesByPropertyId"])}
    },
    "createVehicle": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createVehicle"])}
    },
    "createQRCodeUser": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createQRCodeUser"])}
    },
    "deleteConsent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deleteConsent"])}
    },
    "deletePerson": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deletePerson"])}
    },
    "deletePersonFromProperty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deletePersonFromProperty"])}
    },
    "deleteVehicleFromProperty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deleteVehicleFromProperty"])}
    },
    "getCheckAlive": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["getCheckAlive"])}
    },
    "getEstatesPerCustomerDetails": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["getEstatesPerCustomerDetails"])}
    },
    "getQR": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["getQR"])}
    },
    "getQRCodeLink": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["getQRCodeLink"])}
    },
    "updateConsent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["updateConsent"])}
    }
  },
  "error": {
    "modal-confirm-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "modal-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feilmelding"])},
    "message": {
      "invalid-customer-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tilgang"])},
      "invalid-estate-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangen kan ikke deles pga. ugyldig eiendoms-ID"])},
      "no-accesscards-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen adgangskort er registrert på dette telefonnummeret"])},
      "no-emptying-dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen hentedag funnet"])},
      "not-authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tilgang"])},
      "response-failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ooops! Noe gikk galt, dette kan skyldes stor pågang. Om du har fått denne meldingen flere ganger kontakt <a href=\"https://avfallsor.no/kontakt/kundesenter/\" target=\"_blank\">kundesenter</a>."])},
      "vehicle-already-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beklager! Registreringsnummeret er allerede i bruk. Ta kontakt med <a href=\"https://avfallsor.no/kontakt/kundesenter/\" target=\"_blank\">kundesenter</a> hvis dette er feil."])}
    }
  }
}