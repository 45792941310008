import { api } from '@/plugins/api'
import * as types from '../mutation-types'
import QRCode from 'qrcode'

export const namespaced = true

// state
export const state = () => ({
  qrCodes: [],
  qrCodesEmpty: true,
  qrCodesImages: {},
  qrCodesLoaded: false
})

// getters
export const getters = {
  qrCodes: state => state.qrCodes,
  qrCodesEmpty: state => Object.keys(state.qrCodesImages).length === 0,
  qrCodesImages: state => state.qrCodesImages,
  qrCodesLoaded: state => state.qrCodesLoaded
}

// mutations
export const mutations = {
  [types.QRCODES_LOADING] (state) {
    state.qrCodesLoaded = false
  },
  [types.FETCH_QRCODE_SUCCESS] (state, {
    qrCodes,
    listCodesWithPhoneNumber
  }) {
    state.qrCodes = qrCodes
    state.qrCodesImages = Object.create({})

    if (qrCodes.length) {
      state.qrCodes.forEach((qrCode) => {
        if (qrCode.validity !== 'valid') {
          return
        }
        if (listCodesWithPhoneNumber !== Boolean(qrCode.phoneNumber)) {
          return
        }
        QRCode.toString(qrCode.cardNumber)
          .then(url => {
            const title = (qrCode.address || qrCode.cardProfileName)
            state.qrCodesImages[qrCode.cardNumber] = {
              isDeleting: false,
              phoneNumber: qrCode.phoneNumber || '',
              title,
              url
            }
          })
          .catch(err => {
            console.error(err)
          })
      })
    }
    state.qrCodesLoaded = true
  },
  [types.FETCH_QRCODE_FAILURE] (state) {
    state.qrCodes = []
    state.qrCodesImages = []
    state.qrCodesLoaded = true
  }
}

// actions
export const actions = {
  createQRCodeUser ({ commit }, {
    firstName,
    lastName,
    phoneNumber
  }) {
    return new Promise((resolve, reject) => {
      api.requests.createQRCodeUser(firstName, lastName, phoneNumber, this.state.property.selectedPropertyID).then((res) => {
        resolve()
      }).catch((res) => {
        reject(new Error('fail'))
      })
    })
  },
  getQRCodeLink ({ commit }, { phoneNumber }) {
    return new Promise((resolve, reject) => {
      api.requests.getQRCodeLink(phoneNumber)
        .then((res) => {
          if (res?.data?.isSuccessful === 'true') {
            resolve()
          } else {
            reject(new Error('fail'))
          }
        })
        .catch((res) => {
          reject(new Error('fail'))
        })
    })
  },
  getQRCodes ({ commit }, { clearCacheEntry }) {
    commit(types.QRCODES_LOADING)
    return new Promise((resolve, reject) => {
      api.requests.getQRCodesByPropertyId(this.state.property.selectedPropertyID, clearCacheEntry).then((res) => {
        commit(types.FETCH_QRCODE_SUCCESS, {
          qrCodes: res.data,
          listCodesWithPhoneNumber: false // List only property owner QR codes.
        })
        resolve()
      }).catch((res) => {
        commit(types.FETCH_QRCODE_FAILURE, { qrCodes: {} }) // TODO Add error handling
        resolve() // TODO reject(new Error('fail'))
      })
    })
  },
  getQR ({ commit }, { userId, clearCacheEntry }) {
    commit(types.QRCODES_LOADING)
    return new Promise((resolve, reject) => {
      api.requests.getQR(userId, clearCacheEntry).then((res) => {
        commit(types.FETCH_QRCODE_SUCCESS, {
          qrCodes: res.data,
          listCodesWithPhoneNumber: true // List only shared type QR codes. In reality, getQR should return only QR codes which contain phoneNumber anyway.
        })
        resolve()
      }).catch((res) => {
        commit(types.FETCH_QRCODE_FAILURE, { qrCodes: {} }) // TODO Add error handling
        resolve() // TODO reject(new Error('fail'))
      })
    })
  }
}
