import * as types from '@/store/mutation-types'
import { api } from '@/plugins/api'

export const namespaced = true

// state
export const state = () => ({
  termsOfUse: false,
  propertyAccessList: {}
})

// getters
export const getters = {
  termsOfUse: state => state.termsOfUse,
  propertyAccessList: state => state.propertyAccessList
}

// mutations
export const mutations = {
  [types.CONSENT_ACCEPT_TERMS_OF_USE] (state) {
    state.termsOfUse = true
  },
  [types.CONSENT_UPDATE_SUCCESS] (state, { consentId }) {
    state.propertyAccessList[consentId] = {}
  },
  [types.CONSENT_UPDATE_FAILURE] (state) {
  }
}

// actions
export const actions = {
  acceptTermsOfUse ({ commit }) {
    commit(types.CONSENT_ACCEPT_TERMS_OF_USE)
  },
  delete ({ commit }, { phoneNumber }) {
    return new Promise((resolve, reject) => {
      api.requests.deleteConsent(phoneNumber).then((res) => {
        resolve()
      }).catch((res) => {
        resolve() // TODO reject(new Error('fail'))
      })
    })
  },
  updateConsent ({ commit }, { consentId }) {
    return new Promise((resolve, reject) => {
      api.requests.updateConsent(consentId).then((res) => {
        commit(types.CONSENT_UPDATE_SUCCESS, { consentId })
        resolve()
      }).catch((res) => {
        commit(types.CONSENT_UPDATE_FAILURE, {})
        resolve() // TODO reject(new Error('fail'))
      })
    })
  }
}
