<template>
    <div class="login">
      <img class="login-image" src="../assets/images/login-background-2.jpg" alt="" decoding="async" loading="lazy">
      <div class="login-content-container">
        <div class="login-content">
          <h1>{{ isOidcEnabled() ? t('component.login.my-page.heading') : t('component.login.our-page.heading') }}</h1>
          <p>{{ isOidcEnabled() ? t('component.login.my-page.main-text') : t('component.login.our-page.main-text') }}</p>
          <div v-if="!isOidcEnabled() && !store.state.auth.userPid">
            <form @submit.prevent="submit">
              <div>
                <BaseInput
                  v-model="idNumber"
                  type="number"
                  placeholder="07040640252"
                  maxlength="11"
                  :mask="'###########'"
                  :required="true"
                  :error="errors.idNumber"
                />
              </div>
              <p>
                <button class="button" type="submit">
                  {{ t('global.strings.login-as-customer') }}
                  <ButtonLoadingIndicator :isLoading="event.disabled"/>
                </button>
              </p>
            </form>
          </div>
          <p v-if="!isOidcEnabled() && store.state.auth.userPid">
            <router-link :to="{ name: 'select-property' }" class="button">
              {{ t('global.strings.select-property') }}
              <ArrowLogin />
            </router-link>
          </p>
          <p v-if="isOidcEnabled()">
            <router-link :to="{ name: 'select-property' }" class="button">
              {{ store.state.auth.userExpiresAt && isPwa() ? t('global.strings.select-property') : t('global.strings.login') }}
              <ArrowLogin />
            </router-link>
          </p>
          <p class="enhanced-link">
            {{ t('component.login.missing-qrcode') }}
            <router-link :to="{ name: 'resend-qrcode' }">
              {{ t('global.strings.click-here') }}
            </router-link>
          </p>
        </div>
      </div>
    </div>
</template>

<script setup>
import { isOidcEnabled, isPwa } from '../common/helpers'
import { reactive } from 'vue'
import { useField, useForm, defineRule, configure } from 'vee-validate'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import * as AllRules from '@vee-validate/rules'
import ArrowLogin from '@/components/svgs/ArrowLogin'
import BaseInput from '@/components/form/BaseInput'
import ButtonLoadingIndicator from '@/components/ButtonLoadingIndicator'
import validator from 'norsk-validator'

const router = useRouter()
const store = useStore()

const event = reactive({
  disabled: false
})

const { t } = useI18n()

const { handleSubmit, errors } = useForm()

Object.keys(AllRules).forEach(rule => {
  if (typeof AllRules[rule] === 'function') {
    defineRule(rule, AllRules[rule])
  }
})

defineRule('is_id_number', (value) => {
  return validator.fodselsnummer(value) || validator.organisasjonsnummer(value)
})

configure({
  generateMessage: (context) => {
    const fields = {
      idNumber: t('component.login.form-id-number-label')
    }
    const field = fields[context.field]
    const messages = {
      required: t('global.forms.required-field', { field }),
      is_id_number: t('global.forms.invalid-field', { field })
    }
    return messages[context.rule.name]
  }
})

const { value: idNumber } = useField('idNumber', { required: true, is_id_number: true })

const submit = handleSubmit((values) => {
  event.disabled = true
  store.state.oidcStore = [] // TODO oidcStore init shouldn't be required

  store
    .dispatch('auth/updateUser', {
      userPid: values.idNumber,
      userExpiresAt: Math.floor(Date.now() / 1000) + 3600
    })
    .then(() => {
      store.state.property.propertiesLoaded = false // TODO move to property module
      store.dispatch('property/getProperties', { clearCacheEntry: true })
      router.push({ name: 'select-property' })
      event.disabled = false
    })
})
</script>

<style scoped lang="scss">
.login {
  display: flex;
  flex-direction: column;
}
.login-logo {
  width: 60px;
  margin: 1.5rem 2.5rem;
}
.login-image {
  display: none;
  position: absolute;
  object-fit: cover;
  height: calc(100vh - 40px - 1rem);
  z-index: -1;
}
.login-content-container {
  z-index: 1;
}
.login-content {
  background-color: var(--color-light-greenish);
  border-bottom: var(--thin-border);

  padding: 2rem 1rem;
  .button {
    margin: 1rem 0;
    svg {
      margin-left: .3em;
    }
  }
  .enhanced-link {
    font-size: var(--fs-xsmall);

    a {
      font-weight: 600;
    }
  }
}

@media screen and (min-width: 1024px) {
  .login {
    min-height: 50vh;
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }
  .login-warning {
    color: #b33b3a;
  }
  .login-logo {
    display: none;
  }
  .login-image {
    display: initial;
    position: absolute;
    height: auto;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .login-content-container {
    max-width: calc(1280px + 4rem);
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 2rem;
  }
  .login-content {
    max-width: 100%;
    width: 515px;
    padding: 3.5rem 4rem;
    border-radius: var(--box-border-radius);
    .button {
      min-width: 280px;
      width: 0%;
      svg {
        width: 24px;
        height: 20px;
        transition: transform .2s;
      }
      &:hover, &:focus {
        svg {
          transform: translateX(6px);
        }
      }
    }
  }
}
</style>
