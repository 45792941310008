import * as types from '@/store/mutation-types'

export const namespaced = true

// state
export const state = () => ({
  code: '',
  userExpiresAt: '',
  userPid: ''
})

// getters
export const getters = {
  code: state => state.code,
  userExpiresAt: state => state.userExpiresAt,
  userPid: state => state.userPid
}

// mutations
export const mutations = {
  [types.AUTH_UPDATE_USER_EXPIRES_AT] (state, { userPid, userExpiresAt }) {
    state.userPid = userPid
    state.userExpiresAt = userExpiresAt
  }
}

// actions
export const actions = {
  updateUser ({ commit }, { userPid, userExpiresAt }) {
    commit(types.AUTH_UPDATE_USER_EXPIRES_AT, { userPid, userExpiresAt })
  },
  destroySession () {
    sessionStorage.removeItem('avfallsor.minside')

    // Remove auth element from local storage.
    let ls = localStorage.getItem('avfallsor.minside')
    if (!ls) {
      return
    }
    ls = JSON.parse(ls)
    delete ls.auth
    localStorage.setItem('avfallsor.minside', JSON.stringify(ls))
  }
}
