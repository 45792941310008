<template>
<div class="form-group mb-3">
  <label class="form-label" :for="uuid" v-if="label">{{ label }}<span v-if="required" class="form-required">*</span></label>
  <input
    class="form-control"
    v-bind="$attrs"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    :id="uuid"
    :aria-describedby="error ? `${uuid}-error` : null"
    :aria-invalid="error ? true : null"
    v-maska
    :data-maska="mask"
    :required="required"
  >
  <p
    v-if="error"
    class="form-error-message"
    :id="`${uuid}-error`"
    aria-live="assertlive"
  >
    {{ error }}
  </p>
</div>
</template>

<script>
import UniqueID from '@/features/UniqueID'

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    mask: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const uuid = 'base-input-' + UniqueID().getID()
    return {
      uuid
    }
  }
}
</script>
