export const oidcSettings = {
  authority: process.env.VUE_APP_OIDC_CONFIG_AUTHORITY,
  clientId: process.env.VUE_APP_OIDC_CONFIG_CLIENTID,
  client_secret: process.env.VUE_APP_OIDC_CONFIG_CLIENT_SECRET,
  redirectUri: process.env.VUE_APP_OIDC_CONFIG_REDIRECTURI,
  popupRedirectUri: process.env.VUE_APP_OIDC_CONFIG_POPUPREDIRECTURI,
  responseType: 'code',
  scope: 'openid',
  automaticSilentRenew: true,
  automaticSilentSignin: false,
  silentRedirectUri: process.env.VUE_APP_OIDC_CONFIG_SILENTREDIRECTURI,
  post_logout_redirect_uri: process.env.VUE_APP_OIDC_CONFIG_POSTLOGOUTREDIRECTURI
}
