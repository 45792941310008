// auth.js
export const AUTH_UPDATE_USER_EXPIRES_AT = 'AUTH_UPDATE_USER_EXPIRES_AT'

// collection.js
export const COLLECTIONS_LOADING = 'COLLECTIONS_LOADING'
export const FETCH_COLLECTION_SUCCESS = 'FETCH_COLLECTION_SUCCESS'
export const FETCH_COLLECTION_FAILURE = 'FETCH_COLLECTION_FAILURE'
export const FETCH_COLLECTION_STATISTIC_SUCCESS = 'FETCH_COLLECTION_STATISTIC_SUCCESS'
export const FETCH_COLLECTION_STATISTIC_FAILURE = 'FETCH_COLLECTION_STATISTIC_FAILURE'
export const FETCH_COLLECTION_HISTORY_SUCCESS = 'FETCH_COLLECTION_HISTORY_SUCCESS'
export const FETCH_COLLECTION_HISTORY_FAILURE = 'FETCH_COLLECTION_HISTORY_FAILURE'

// consent.js
export const CONSENT_ACCEPT_TERMS_OF_USE = 'CONSENT_ACCEPT_TERMS_OF_USE'
export const CONSENT_UPDATE_FAILURE = 'CONSENT_UPDATE_FAILURE'
export const CONSENT_UPDATE_SUCCESS = 'CONSENT_UPDATE_SUCCESS'

// organization.js
export const ORGANIZATION_MOVE_FAILURE = 'ORGANIZATION_MOVE_FAILURE'
export const ORGANIZATION_MOVE_SUCCESS = 'ORGANIZATION_MOVE_SUCCESS'
export const ORGANIZATION_MOVING = 'ORGANIZATION_MOVING'
export const ORGANIZATION_SELECT = 'ORGANIZATION_SELECT'
export const ORGANIZATIONS_CLEAR = 'ORGANIZATIONS_CLEAR'
export const ORGANIZATIONS_LOAD = 'ORGANIZATIONS_LOAD'
export const ORGANIZATIONS_FETCH_FAILURE = 'ORGANIZATIONS_FETCH_FAILURE'
export const ORGANIZATIONS_FETCH_SUCCESS = 'ORGANIZATIONS_FETCH_SUCCESS'

// property.js
export const PROPERTIES_FETCH_FAILURE = 'PROPERTIES_FETCH_FAILURE'
export const PROPERTIES_FETCH_SUCCESS = 'PROPERTIES_FETCH_SUCCESS'
export const PROPERTY_SELECT = 'PROPERTY_SELECT'

// qrcode.js
export const QRCODES_LOADING = 'QRCODES_LOADING'
export const FETCH_QRCODE_SUCCESS = 'FETCH_QRCODE_SUCCESS'
export const FETCH_QRCODE_FAILURE = 'FETCH_QRCODE_FAILURE'

// user.js
export const USERS_LOADING = 'USERS_LOADING'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'
export const DELETE_USER = 'DELETE_USER'
export const UPDATE_USER = 'UPDATE_USER'

// vehicle.js
export const VEHICLES_LOADING = 'VEHICLES_LOADING'
export const FETCH_VEHICLE_SUCCESS = 'FETCH_VEHICLE_SUCCESS'
export const FETCH_VEHICLE_FAILURE = 'FETCH_VEHICLE_FAILURE'
export const DELETE_VEHICLE = 'DELETE_VEHICLE'
