import { api } from '@/plugins/api'
import * as types from '../mutation-types'

export const namespaced = true

// state
export const state = () => ({
  collectionDates: {},
  collectionDatesEmpty: true,
  collectionDatesLoaded: false,
  collectionHistory: [],
  collectionHistoryLoaded: false,
  collectionShowAlternativeText: false,
  usageStatistics: [],
  visitOverviewLoaded: false
})

// getters
export const getters = {
  collectionDates: state => state.collectionDates,
  collectionDatesEmpty: state => state.collectionDatesEmpty,
  collectionDatesLoaded: state => state.collectionDatesLoaded,
  collectionHistory: state => state.collectionHistory,
  collectionHistoryLoaded: state => state.collectionHistoryLoaded,
  collectionShowAlternativeText: state => state.collectionShowAlternativeText,
  usageStatistics: state => state.usageStatistics,
  visitOverviewLoaded: state => state.visitOverviewLoaded
}

// mutations
export const mutations = {
  [types.COLLECTIONS_LOADING] (state) {
    state.collectionDates = []
    state.collectionDatesLoaded = false
  },
  [types.FETCH_COLLECTION_SUCCESS] (state, { collectionDates }) {
    state.collectionDatesLoaded = false

    // Remove dates that are set as not visible
    collectionDates.forEach((element, index) => {
      if (element.minsideSynlig === false) { // When minsideAlternativTekst in use, change the condition to (!element.minsideSynlig && !element.minsideAlternativTekst)
        state.collectionShowAlternativeText = true
        delete collectionDates[index]
      }
    })

    state.collectionDates = collectionDates.reduce((array, element) => {
      element.date = element.dato
      element.id = element.fraksjonId
      element.name = element.fraksjon
      element.frequency = actions.getFrequency(element.frekvensIntervall, element.rutenavn)
      const collectionData = actions.getCollectionDataByType(element.fraksjonId)
      element.className = collectionData.class
      element.isVisible = element.minsideSynlig
      element.altText = element.minsideAlternativTekst
      const {
        date,
        id,
        name,
        frequency,
        className,
        isVisible,
        altText
      } = element
      array[date] = [...array[date] || [], {
        id,
        name,
        frequency,
        className,
        isVisible,
        altText
      }]
      return array
    }, {})

    state.collectionDatesEmpty = Object.keys(state.collectionDates).length === 0
    state.collectionDatesLoaded = true
  },
  [types.FETCH_COLLECTION_FAILURE] (state) {
    state.collectionDates = {}
    state.collectionDatesEmpty = true
    state.collectionDatesLoaded = true
  },
  [types.FETCH_COLLECTION_HISTORY_SUCCESS] (state, { collectionHistory }) {
    state.collectionHistoryLoaded = false

    const collectionByFraction = collectionHistory.reduce((array, element) => {
      element.month = (new Date(element.date)).getMonth() + 1
      const {
        month,
        fractionId
      } = element
      array[fractionId] = [...array[fractionId] || [],
        month
      ]
      return array
    }, {})
    const collectionByOccurence = []
    Object.entries(collectionByFraction).forEach(([fraction, value]) => {
      // Count occurrences by month
      const occurrencePerFraction = value.reduce((obj, b) => {
        obj[b] = ++obj[b] || 1
        return obj
      }, {})
      collectionByOccurence[fraction] = occurrencePerFraction
    })
    const collection = []
    // Generate array of month names
    const months = actions.getMonths()
    Object.entries(collectionByOccurence).forEach(([fraction, occurrences]) => {
      const bars = []
      months.forEach((month, index) => {
        const bar = {
          number: occurrences[index + 1] || 0,
          line1: month
        }
        bars.push(bar)
      })
      const occurrenceValues = Object.values(occurrences)
      const collectionData = actions.getCollectionDataByType(fraction)
      const collectionElement = {
        type: 'bars',
        barColor: collectionData.color,
        heading: collectionData.title,
        bars,
        maxHeight: Math.max(...occurrenceValues)
      }
      collection.push(collectionElement)
    })
    state.collectionHistory = collection
    state.collectionHistoryLoaded = true
  },
  [types.FETCH_COLLECTION_HISTORY_FAILURE] (state) {
    state.collectionHistory = {}
    state.collectionHistoryLoaded = true
  },
  [types.FETCH_COLLECTION_STATISTIC_SUCCESS] (state, { usageStatistics }) {
    state.visitOverviewLoaded = false
    // Note that month numbers do not correspond to array indices
    const statisticsByMonth = state.usageStatistics = usageStatistics.reduce((array, element) => {
      element.month = (new Date(element.date)).getMonth()
      element.occurrence = element.deliveredWaste.length
      const {
        month,
        occurrence
      } = element
      array[month] = [...array[month] || [],
        occurrence
      ]
      return array
    }, {})
    const months = actions.getMonths()
    const bars = []
    const totalVisits = []
    months.forEach((month, index) => {
      let visitsPerMonth = 0
      if (statisticsByMonth[index]) {
        visitsPerMonth = statisticsByMonth[index].reduce((a, b) => a + b, 0) // Sum occurrences
      }
      const bar = {
        number: visitsPerMonth,
        line1: month
      }
      bars.push(bar)
      totalVisits.push(visitsPerMonth)
    })
    const summary = {
      type: 'content',
      heading: '', // TODO i18n
      number: totalVisits.reduce((a, b) => a + b, 0),
      text: 'totalt' // TODO i18n
    }
    const statisticsElement = {
      type: 'bars',
      barColor: 'totalt', // TODO i18n
      heading: 'Besøk per måned', // TODO i18n
      bars,
      maxHeight: Math.max(...totalVisits)
    }
    state.usageStatistics = [
      summary,
      statisticsElement
    ]
    state.visitOverviewLoaded = true
  },
  [types.FETCH_COLLECTION_STATISTIC_FAILURE] (state) {
    state.usageStatistics = {}
    state.visitOverviewLoaded = true
  }
}

// actions
export const actions = {
  getCollectionDates ({ commit }) {
    commit(types.COLLECTIONS_LOADING)
    return new Promise((resolve, reject) => {
      api.requests.getEmptyingDates(this.state.property.selectedPropertyID).then((res) => {
        commit(types.FETCH_COLLECTION_SUCCESS, { collectionDates: res.data })
        resolve()
      }).catch((res) => {
        commit(types.FETCH_COLLECTION_FAILURE, { collectionDates: {} }) // TODO Add error handling, API returns 404 when no properties are found
        resolve() // reject(new Error('fail'))
      })
    })
  },
  getCollectionHistory ({ commit }) {
    const currentYear = new Date().getFullYear()
    const fromDate = currentYear + '-01-01'
    const toDate = currentYear + '-12-31'
    return new Promise((resolve, reject) => {
      api.requests.getEmptyingHistory(this.state.property.selectedPropertyID, fromDate, toDate).then((res) => {
        commit(types.FETCH_COLLECTION_HISTORY_SUCCESS, { collectionHistory: res.data })
        resolve()
      }).catch((res) => {
        commit(types.FETCH_COLLECTION_HISTORY_FAILURE, { collectionDates: {} }) // TODO Add error handling, API returns 404 when no properties are found
        resolve() // reject(new Error('fail'))
      })
    })
  },
  getCollectionDataByType (type) {
    const collectionData = {
      1111: {
        title: 'Bioavfall', // this.$i18n.t('component.collections.trash.biowaste'),
        class: 'bio',
        color: 'brown'
      },
      1322: {
        title: 'Glass- og metallemballasje', // this.$i18n.t('component.collections.trash.glass-metal'),
        class: 'glass',
        color: 'red'
      },
      2499: {
        title: 'Papp, papir og plastemballasje', // this.$i18n.t('component.collections.trash.paper-plastic'),
        class: 'cardboard',
        color: 'green'
      },
      9011: {
        title: 'Restavfall', // this.$i18n.t('component.collections.trash.residual'),
        class: 'residual',
        color: 'blue'
      }
    }
    return collectionData[type]
  },
  getFrequency (interval, route) {
    let weekday = ''
    const weekdays = {
      Mandag: 'mandager', // this.$i18n.t('component.collections.weekdays.monday', 2),
      Tirsdag: 'tirsdager', // this.$i18n.t('component.collections.weekdays.tuesday', 2),
      Onsdag: 'onsdager', // this.$i18n.t('component.collections.weekdays.wednesday', 2),
      Torsdag: 'torsdager', // this.$i18n.t('component.collections.weekdays.thursday', 2),
      Fredag: 'fredager' // this.$i18n.t('component.collections.weekdays.friday', 2)
    }
    Object.entries(weekdays).forEach(entry => {
      const [key, value] = entry
      if (route.indexOf(key) !== -1) {
        weekday = value
      }
    })
    const frequencies = {
      1: `Tømmes ${weekday} hver uke`, // this.$i18n.t('component.collections.frequencies.every-week', { weekday: weekday }),
      2: `Tømmes ${weekday} hver 14. dag`, // this.$i18n.t('component.collections.frequencies.every-second-week', { weekday: weekday }),
      4: 'Tømmes hver 4. uke', // this.$i18n.t('component.collections.frequencies.every-fourth-week'),
      8: 'Tømmes hver 8. uke' // this.$i18n.t('component.collections.frequencies.every-eighth-week'),
    }
    return frequencies[interval]
  },
  getUsageStatistics ({ commit }) {
    const currentYear = new Date().getFullYear()
    const fromDate = currentYear + '-01-01'
    const toDate = currentYear + '-12-31'
    return new Promise((resolve, reject) => {
      api.requests.getUsageStatistics(this.state.property.selectedPropertyID, fromDate, toDate).then((res) => {
        commit(types.FETCH_COLLECTION_STATISTIC_SUCCESS, { usageStatistics: res.data })
        resolve()
      }).catch((res) => {
        commit(types.FETCH_COLLECTION_STATISTIC_FAILURE, { usageStatistics: {} })
        resolve() // TODO reject(new Error('fail'))
      })
    })
  },
  getMonths () {
    return [...Array(12).keys()].map(key => new Date(0, key).toLocaleString(process.env.VUE_APP_I18N_LOCALE, { month: 'short' }))
  }
}
