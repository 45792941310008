import store from '@/store'
import { httpClient } from '@/plugins/api'

// Request interceptor
httpClient.interceptors.request.use(config => {
  if (store.state.auth.userPid) {
    config.headers.set('x-wss-customerid', store.state.auth.userPid)
  }
  if (store.state.organization.selectedEntityNumber) {
    config.headers.set('x-wss-delegatedid', store.state.organization.selectedEntityNumber)
  }
  if (store.state.oidcStore && store.state.oidcStore.access_token) {
    config.headers.set('x-wss-difitoken', store.state.oidcStore.access_token)
  }
  return config
}, error => {
  return Promise.reject(error)
})

// Response interceptor
httpClient.interceptors.response.use(response => {
  return response
}, error => {
  if (['ECONNABORTED', 'ETIMEDOUT'].includes(error.code)) {
    store.state.errorMessage = error.code
  } else if (error.response.data && 'errors' in error.response.data) {
    store.state.errorMessage = error.response.data.errors
  }
  return Promise.reject(error)
})
