import { api } from '@/plugins/api'
import * as types from '../mutation-types'

export const namespaced = true

// state
export const state = () => ({
  vehicles: {},
  vehicleList: [],
  vehicleListLoaded: false,
  vehicleListRaw: []
})

// getters
export const getters = {
  vehicles: state => state.vehicles,
  vehicleList: state => state.vehicleList,
  vehicleListLoaded: state => state.vehicleListLoaded,
  vehicleListRaw: state => state.vehicleListRaw
}

// mutations
export const mutations = {
  [types.VEHICLES_LOADING] (state) {
    state.vehicleList = []
    state.vehicleListRaw = []
    state.vehicleListLoaded = false
  },
  [types.FETCH_VEHICLE_SUCCESS] (state, { vehicles }) {
    state.vehicleListLoaded = false
    state.vehicles = vehicles // TODO remove
    state.vehicleListRaw = vehicles

    if (vehicles.length !== 0) {
      vehicles.forEach((vehicle) => {
        const registrationNumber = vehicle.registrationNumber
        if (!registrationNumber) {
          return
        }
        const item = {
          key: registrationNumber,
          title: registrationNumber,
          isDeleting: false
        }
        state.vehicleList.push(item)
      })
    }

    state.vehicleListLoaded = true
  },
  [types.FETCH_VEHICLE_FAILURE] (state) {
    state.vehicles = {}
    state.vehicleList = []
    state.vehicleListRaw = []
    state.vehicleListLoaded = true
  },
  [types.DELETE_VEHICLE] (state, { registrationNumber }) {
    for (const i in state.vehicleList) {
      if (state.vehicleList[i].key === registrationNumber) {
        state.vehicleList[i].isDeleting = true
      }
    }
  }
}

// actions
export const actions = {
  createVehicle ({ commit }, { registrationNumber }) {
    return new Promise((resolve, reject) => {
      api.requests.createVehicle(registrationNumber, this.state.property.selectedPropertyID, this.state.auth.userPid)
        .then((res) => {
          resolve()
        })
        .catch((res) => {
          reject(new Error('fail'))
        })
    })
  },
  deleteVehicleFromProperty ({ commit }, { registrationNumber }) {
    commit(types.DELETE_VEHICLE, { registrationNumber })
    return new Promise((resolve, reject) => {
      api.requests.deleteVehicleFromProperty(registrationNumber)
        .then((res) => {
          resolve()
        }).catch(() => {
          reject(new Error('fail'))
        })
    })
  },
  getVehicles ({ commit }, { clearCacheEntry }) {
    commit(types.VEHICLES_LOADING)
    return new Promise((resolve, reject) => {
      api.requests.getVehiclesByPropertyId(this.state.property.selectedPropertyID, clearCacheEntry)
        .then((res) => {
          commit(types.FETCH_VEHICLE_SUCCESS, { vehicles: res.data })
          resolve()
        })
        .catch((res) => {
          commit(types.FETCH_VEHICLE_FAILURE) // TODO Add error handling
          reject(new Error('fail'))
        })
    })
  }
}
