import { api } from '@/plugins/api'
import * as types from '../mutation-types'

export const namespaced = true

// state
export const state = () => ({
  properties: [],
  propertiesLoaded: false,
  selectedPropertyID: ''
})

// getters
export const getters = {
  properties: state => state.properties,
  propertiesLoaded: state => state.propertiesLoaded,
  selectedPropertyID: state => state.selectedPropertyID,
  selectedProperty: state => {
    const selectedProperty = state.properties.filter(property => property.id === state.selectedPropertyID)
    return selectedProperty.length > 0
      ? selectedProperty[0]
      : {
          id: '',
          title: '',
          owner: '',
          entityNumber: ''
        }
  }
}

// mutations
export const mutations = {
  [types.PROPERTIES_FETCH_FAILURE] (state) {
    state.properties = []
    state.propertiesLoaded = true
  },
  [types.PROPERTIES_FETCH_SUCCESS] (state, { properties }) {
    state.properties = properties.map(property => {
      return {
        id: property.EstateId,
        title: property.Adress + ', ' + property.Municipality,
        owner: property.Owner,
        entityNumber: ''
      }
    })
    if (state.properties.length > 0) {
      if (state.selectedPropertyID === '' || state.properties.filter(property => property.id === state.selectedPropertyID).length === 0) {
        state.selectedPropertyID = state.properties[0].id
      }
    }
    state.propertiesLoaded = true
  },
  [types.PROPERTY_SELECT] (state, { propertyId }) {
    state.selectedPropertyID = propertyId
    for (const i in state.properties) {
      if (state.properties[i].id === propertyId) {
        this.dispatch('organization/selectOrganization', { entityNumber: state.properties[i].entityNumber })
      }
    }
  }
}

// actions
export const actions = {
  getProperties ({ commit }) {
    return new Promise((resolve, reject) => {
      api.requests.getEstatesPerCustomer().then((res) => {
        commit(types.PROPERTIES_FETCH_SUCCESS, { properties: res.data || [] })
        resolve()
      }).catch((res) => {
        commit(types.PROPERTIES_FETCH_FAILURE)
        resolve()
      })
    })
  },
  selectProperty ({ commit }, { propertyId }) {
    commit(types.PROPERTY_SELECT, { propertyId })
  }
}
