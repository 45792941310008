window.addEventListener('load', () => {
  if (navigator.onLine) {
    document.querySelectorAll('.online-status-check').forEach((element) => {
      element.classList.remove('disabled')
    })
    return
  }
  document.querySelectorAll('.online-status-check').forEach((element) => {
    element.classList.add('disabled')
  })
})

window.addEventListener('online', () => {
  document.querySelectorAll('.online-status-check').forEach((element) => {
    element.classList.remove('disabled')
  })
})

window.addEventListener('offline', () => {
  document.querySelectorAll('.online-status-check').forEach((element) => {
    element.classList.add('disabled')
  })
})

document.addEventListener('DOMContentLoaded', () => {
  const body = document.querySelector('body')

  window.addEventListener('click', (event) => {
    if (event.screenX === 0 && event.screenY === 0) {
      return
    }

    body.classList.remove('is-tab-navigating')
  })

  window.addEventListener('keyup', (event) => {
    if (event.key !== 'Tab') {
      return
    }

    body.classList.add('is-tab-navigating')
  })
})
