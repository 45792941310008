
import store from '@/store'
import VuexPersistence from 'vuex-persist'
import { createStore } from 'vuex'
import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import { oidcSettings } from '@/config/oidc'
import { isOidcEnabled } from '@/common/helpers'

// Load store modules dynamically.
const requireContext = require.context('./modules', false, /.*\.js$/)

let modules = requireContext.keys()
  .map(file =>
    [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
  )

if (isOidcEnabled()) {
  modules.push(['oidcStore', vuexOidcCreateStoreModule(oidcSettings, {
    namespaced: true,
    removeUserWhenTokensExpire: false
  },
  {
    userLoaded: (user) => {
      store.dispatch('auth/updateUser', {
        userPid: user.profile.pid,
        userExpiresAt: user.expires_at
      })
    }
  })])
}

modules = modules.reduce((modules, [name, module]) => {
  if (module.namespaced === undefined) {
    module.namespaced = true
  }

  return {
    ...modules,
    [name]: module
  }
}, {}
)

const vuexLocalStorage = new VuexPersistence({
  key: 'avfallsor.minside',
  storage: window.localStorage,
  modules: ['auth', 'consent']
})

const vuexSessionStorage = new VuexPersistence({
  key: 'avfallsor.minside',
  storage: window.sessionStorage,
  modules: ['organization', 'property']
})

export default createStore({
  modules,
  plugins: [vuexLocalStorage.plugin, vuexSessionStorage.plugin]
})
