<template>
  <header class="header-full">
    <div class="bar main-bar container">
      <div class="left">
        <router-link :to="{name: oidcIsAuthenticated ? 'select-property' : 'login-screen'}">
          <img class="logo" src="../../assets/svgs/avfallsor-logo-full.svg" alt="Avfall Sør - Min side" decoding="async" loading="lazy">
        </router-link>
      </div>
      <div class="right">
        <router-link :to="{}" @click.prevent="logIn" v-if="showLogout && isOidcEnabled() && !hasAccess()" class="button online-status-check">
          {{ t('global.strings.login') }}
          <ArrowLogin />
        </router-link>
        <router-link :to="{}" @click.prevent="logOut" v-if="showLogout && hasAccess()" class="button is-style-outline logout-button online-status-check" href="#">
          {{ t('global.strings.logout') }}
          <ArrowLogout/>
        </router-link>
      </div>
    </div>
    <div v-if="showHeaderBottomBar" class="bar bottom-bar">
      <div class="container bottom-bar-inner">
        <div class="left">
          <Breadcrumbs :breadcrumbs="breadcrumbs" />
        </div>
        <div class="center">

        </div>
        <div class="right">
          <span class="welcome-message" v-if="showWelcomeMessage">{{ welcomeText }}</span>
          <span v-if="showPropertyInfo && !showPropertySelector">{{ propertyInfo }}</span>
          <div class="form-group form-group--inline" v-if="showPropertyInfo && showPropertySelector">
            <label for="select-property" class="form-label select-property-form-label">Vis eiendom</label>
            <select id="select-property" class="form-select" name="property"
                    @change="changeProperty($event)">
              <option v-for="propertyItem in properties" v-bind:key="propertyItem.id" :value="propertyItem.id"
                      :selected="propertyItem.id === selectedProperty.id">
                {{ propertyItem.owner }} - {{ propertyItem.title }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bar mobile-bar"
      :class="{ 'mobile-bar--background-image': showMobileBackground }"
    >
      <div class="left">
       <router-link :to="{name: 'select-property'}">
          <img class="logo" src="../../assets/svgs/avfallsor-logo-full.svg" alt="Avfall Sør - Min side - Logo" decoding="async" loading="lazy">
        </router-link>
      </div>
      <div class="right">
        <router-link :to="{}" @click.prevent="logIn" v-if="showLogout && isOidcEnabled() && !hasAccess()" class="button online-status-check">
          {{ t('global.strings.login') }}
          <ArrowLogin />
        </router-link>
        <router-link :to="{}" @click.prevent="logOut" v-if="showLogout && hasAccess()" class="logout-button button is-style-outline online-status-check">
          {{ t('global.strings.logout') }}
          <ArrowLogout/>
        </router-link>
      </div>
      <div v-if="breadcrumbs || showPropertySelector" class="bar mobile-bar-bottom">
        <div class="mobile-bar-bottom-inner">
          <Breadcrumbs :breadcrumbs="breadcrumbs" />
          <div class="mobile-property-single">
            <div class="welcome-message" v-if="showWelcomeMessage">{{ welcomeText }}</div>
            <div class="property-info" v-if="showPropertyInfo && !showPropertySelector">{{ propertyInfo }}</div>
          </div>
          <div class="property-selector form-group form-group--inline" v-if="showPropertyInfo && showPropertySelector">
            <label for="select-property-mobile" class="form-label select-property-form-label">Vis eiendom</label>
            <select id="select-property-mobile" class="form-select" name="property" @change="changeProperty($event)">
              <option v-for="propertyItem in properties" v-bind:key="propertyItem.id" :value="propertyItem.id"
                      :selected="propertyItem.id === selectedProperty.id">
                {{ propertyItem.title }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showBack" class="bar sub-bottom-bar container">
      <router-link :to="{name: 'my-page'}" class="back-link">
        <span class="back-link-arrow"></span>{{ t('global.strings.back-button-text') }}
      </router-link>
    </div>
    <div v-if="showBackToLogin" class="bar sub-bottom-bar container">
      <router-link :to="{name: 'login-screen'}" class="back-link">
        <span class="back-link-arrow"></span>{{ t('global.strings.back-to-login-button-text') }}
      </router-link>
    </div>
  </header>
</template>

<script setup>
import { hasAccess } from '@/common/auth'
import { isOidcEnabled, isPwa } from '@/common/helpers'
import { ref, computed, watch, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ArrowLogin from '@/components/svgs/ArrowLogin'
import ArrowLogout from '@/components/svgs/ArrowLogout'
import Breadcrumbs from '@/components/Breadcrumbs'
import Swal from 'sweetalert2'

defineProps({
  breadcrumbs: [Array, Boolean],
  showBack: {
    type: Boolean,
    default: true
  },
  showLogout: {
    type: Boolean,
    default: true
  },
  showMobileBackground: {
    type: Boolean,
    default: false
  },
  showPropertyInfo: {
    type: Boolean,
    default: true
  },
  showWelcomeMessage: {
    type: Boolean,
    default: false
  },
  showHeaderBottomBar: {
    type: Boolean,
    default: true
  },
  showBackToLogin: {
    type: Boolean,
    default: false
  }
})

const store = useStore()
const router = useRouter()
const { t } = useI18n()

const errorList = {
  'response-failure': 'ECONNABORTED',
  'invalid-customer-id': 'The header value x-wss-customerid is empty or not valid',
  'invalid-estate-id': 'Invalid value for "estateId" Should be GUID',
  'no-accesscards-registered': 'No accesscards registered on this phonenumber',
  // 'no-emptying-dates': 'No emptyingdates for estate',
  // 'no-organizations': 'No organizations for customerid',
  // 'no-valid-entity-number': 'is not a valid organization number or social security number',
  'not-authorized': 'ID-Porten: Not authorized',
  'vehicle-already-registered': 'Vehicle already registered on property'
}
const locationReload = ref(false)
const showPropertySelector = ref(true)

// computed

const properties = computed(() => store.getters['property/properties'])
const selectedProperty = computed(() => store.getters['property/selectedProperty'])
const oidcIsAuthenticated = computed(() => store.getters['oidcStore/oidcIsAuthenticated'])

const userInfo = computed(() => {
  const elements = []
  if (selectedProperty.value.owner) {
    elements.push(selectedProperty.value.owner)
  }
  return elements.join(' - ')
})

const propertyInfo = computed(() => {
  const elements = []
  if (userInfo.value) {
    elements.push(userInfo.value)
  }
  if (selectedProperty.value.title) {
    elements.push(selectedProperty.value.title)
  }
  return elements.join(' - ')
})

const welcomeText = computed(() => {
  const elements = []
  elements.push(t('global.strings.welcome'))
  if (userInfo.value) {
    elements.push(userInfo.value)
  }
  return elements.join(' ')
})

// methods

const signOutOidc = () => store.dispatch('oidcStore/signOutOidc')

const changeProperty = (event) => {
  const propertyId = event.target[event.target.selectedIndex].value
  store.dispatch('property/selectProperty', { propertyId })
}

// Destroy the storage which makes signOutOidc() redirect to the ID-Porten login page.
const logIn = () => {
  store.dispatch('auth/destroySession')
  router.go() // Destroy buildMemoryStorage.
}

// Log out only from the current session and ID-porten. This allows for continued use of the app as a PWA when OICD is enabled.
const logOut = () => {
  sessionStorage.removeItem('avfallsor.minside')
  if (!isOidcEnabled()) {
    // Remove buildWebStorage.
    Object.keys(localStorage).forEach((key) => {
      if (/^avfallsor\.minside\.cache:/.test(key)) {
        localStorage.removeItem(key)
      }
    })
    store.dispatch('organization/clear')
    store.dispatch('property/selectProperty', { propertyId: null })
    store.dispatch('auth/updateUser', { userPid: null, userExpiresAt: 0 })
    store.dispatch('auth/destroySession')
    router.push('/')
    return
  }
  signOutOidc()
}

const showErrorModal = function (html) {
  Swal.fire({
    showCancelButton: false,
    title: t('error.modal-title'),
    html,
    confirmButtonText: t('error.modal-confirm-button-text')
  }).then((result) => {
    if (result.isConfirmed && locationReload.value) {
      location.reload()
    }
  })
}

// watch

watch(() => store.state.errorMessage, (newValue, oldValue) => {
  if (!newValue) {
    return
  }
  locationReload.value = false
  let errorFound = false
  Object.entries(errorList).forEach(([key, value]) => {
    if (newValue === 'ECONNABORTED') {
      locationReload.value = true
    }
    if (newValue.includes(value)) {
      newValue = t('error.message.' + key)
      errorFound = true
    }
  })
  // Show error if found on the list
  if (!errorFound) {
    return
  }
  showErrorModal(newValue) // Show error message
  store.state.errorMessage = '' // Clear error message
})

// lifecycle hooks

onMounted(() => {
  if (properties.value.length < 2) {
    showPropertySelector.value = false
  }
  if (isPwa()) {
    document.querySelector('body').classList.add('pwa')
  }
})

</script>

<style lang="scss" scoped>
.select-property-form-label {
  white-space: nowrap;
  margin-top: 5px;
  margin-bottom: 5px;
}
.header-full {
  display: flex;
  flex-direction: column;
  align-items: center;

  .bar {
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    > div {
      &.left {
        text-align: left;

        a {
          display: block;
        }
      }

      &.center {
        text-align: center;
      }

      &.right {
        text-align: right;
        font-weight: 700;
      }
    }
  }

  .main-bar {
    display: none;
  }

  .bottom-bar {
    display: none;
  }

  .sub-bottom-bar {
    margin-top: 1rem;
    margin-bottom: 1rem;
    .back-link {
      margin-left: 1rem;
      font-weight: 600;
      color: var(--color-blueish);
      font-size: var(--fs-tiny);

      &-arrow {
        width: 4px;
        height: 7px;
        margin-right: 5px;
        background-image: url(../../assets/svgs/arrow.svg);
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        transform: rotate(180deg);
        display: inline-block;
      }
    }
  }

  .welcome-message,
  .property-info {
    font-size: var(--fs-normal);
    font-weight: 600;
  }

  .mobile-bar {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 1rem 0;
    font-size: var(--fs-tiny);

    .left, .right {
      margin-bottom: 1rem;
    }

    .logo {
      width: 150px;
      max-width: 100%;
    }

    a {
      display: flex;
      align-items: center;
      line-height: 1;
    }

    .back-button {
      svg {
        height: 16px;
        margin-right: 5px;
      }
    }

    .logout-button {
      svg {
        height: 19px;
        margin-left: 5px;
      }
    }

    .mobile-bar-bottom {
      width: 100vw;
      background-color: var(--color-light-greenish);
      margin-left: -1rem;
      margin-right: -1rem;
      margin-top: 0;
      padding-top: 1rem;
      padding-bottom: 1rem;
      &-inner {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
    .property-selector {
      width: 100%;
    }
  }
}

.login-screen .header-full .bottom-bar {
  background-color: transparent;
}

@media screen and (min-width: 1024px) {
  .header-full {
    padding-left: 2rem;
    padding-right: 2rem;

    .main-bar {
      display: flex;
      margin: 2rem 0 1rem;
    }

    .bottom-bar {
      display: block;
      background-color: var(--color-light-greenish);
      width: calc(100% + 4rem);
      margin-left: -2rem;
      margin-right: -2rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .bottom-bar-inner {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      font-size: var(--fs-xsmall);
      color: var(--color-blueish);
      align-items: center;
      min-height: 72px;
    }

    .mobile-bar {
      display: none;
    }

    .logo {
      width: 210px;
    }

    .logout-button {
      svg {
        width: 16px;
        height: 16px;
        margin-left: 10px;
      }
    }
    .sub-bottom-bar {
      font-size: var(--fs-normal);
      .back-link {
        font-size: var(--fs-small);
        margin-left: 0;
        &-arrow {
          width: 6px;
          height: 13px;
          margin-right: 9px;
        }
      }
    }
  }
}
</style>
