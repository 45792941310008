import axios from 'axios'
import { hasAccess } from '@/common/auth'
import { isOidcEnabled, isPwa } from '../common/helpers'
import { setupCache, buildMemoryStorage, buildWebStorage, defaultHeaderInterpreter } from 'axios-cache-interceptor' // Replace with 'axios-cache-interceptor/dev' when in development mode.

const axiosInstance = axios.create({
  timeout: 120 * 1000 // 2 minutes.
})

const appendCacheParam = (clearCacheEntry, params = {}) => {
  if (clearCacheEntry && hasAccess()) {
    params.cache = false // Disable the cache for this request.
  }
  return params
}

export const httpClient = setupCache(axiosInstance, {
  debug: process.env.VUE_APP_DEBUG === 'true' ? console.log : undefined, // This option only works when targeting a Development build, see https://axios-cache-interceptor.js.org/guide/debugging
  // Handle response caching, regardless of cache headers returned by the API
  headerInterpreter: (headers) => {
    return process.env.VUE_APP_CACHE_EXPIRY_TIME || defaultHeaderInterpreter
  },
  storage: isPwa() || !isOidcEnabled() ? buildWebStorage(localStorage, 'avfallsor.minside.cache:') : buildMemoryStorage()
})

export const api = {
  requestStrings: {
    createVehicle: '/ws/simple/upsertAllowedVehicles{apiFunctionPostfix}',
    createQRCodeUser: '/ws/simple/updateQRCodeUsers{apiFunctionPostfix}',
    deleteConsent: '/ws/simple/deleteConsent{apiFunctionPostfix}?phoneNumber={phoneNumber}',
    deletePerson: '/ws/simple/deletePersonFromProperty{apiFunctionPostfix}?pid={personId}',
    deletePersonFromProperty: '/ws/simple/deletePersonFromProperty{apiFunctionPostfix}?phoneNumber={phoneNumber}&estateId={estateId}',
    deleteVehicleFromProperty: '/ws/simple/deleteVehicleFromProperty{apiFunctionPostfix}?regno={registrationNumber}',
    getCheckAlive: '/ws/simple/getCheckAlive{apiFunctionPostfix}',
    getEmptyingHistory: '/ws/simple/getEmptyingHistory{apiFunctionPostfix}?estateId={estateId}&dateFrom={fromDate}&dateTo={toDate}',
    getEmptyingDates: '/ws/simple/getEmptyingDates{apiFunctionPostfix}?estateId={estateId}',
    getEstatesPerCustomerDetails: '/ws/simple/getEstatesPerCustomerDetails{apiFunctionPostfix}',
    getEstatesPerCustomer: '/ws/simple/getEstatesPerCustomer{apiFunctionPostfix}',
    getOrganizations: '/ws/simple/getOrganizations{apiFunctionPostfix}',
    getQR: '/ws/simple/getQR{apiFunctionPostfix}?userId={userId}',
    getQRCode: '/ws/simple/getQRCode{apiFunctionPostfix}?phoneNumber={phoneNumber}&regno={registrationNumber}',
    getQRCodeLink: '/ws/simple/getQRCodeLink{apiFunctionPostfix}?phoneNumber={phoneNumber}',
    getQRCodesByPropertyId: '/ws/simple/getQRCodesByPropertyId{apiFunctionPostfix}?estateId={estateId}',
    getUsageStatistics: '/ws/simple/getUsageStatistics{apiFunctionPostfix}?estateId={estateId}&fromDate={fromDate}&toDate={toDate}',
    getVehiclesByPropertyId: '/ws/simple/getVehiclesByPropertyId{apiFunctionPostfix}?estateId={estateId}',
    updateConsent: '/ws/simple/updateConsent{apiFunctionPostfix}'
  },
  requests: {
    createVehicle (registrationNumber, estateId, personId) {
      const requestString = api.helpers.processRequestString(api.requestStrings.createVehicle)
      return httpClient.post(requestString, {
        registrationNumber,
        estateId,
        pid: personId
      })
    },
    createQRCodeUser (firstName, lastName, phoneNumber, estateId) {
      const requestString = api.helpers.processRequestString(api.requestStrings.createQRCodeUser)
      return httpClient.post(requestString, {
        firstName,
        surname: lastName,
        mobileNumber: phoneNumber,
        estateId
      })
    },
    deleteConsent (phoneNumber) {
      const requestString = api.helpers.processRequestString(api.requestStrings.deleteConsent, { phoneNumber })
      return httpClient.delete(requestString)
    },
    deletePerson (personId) {
      const requestString = api.helpers.processRequestString(api.requestStrings.deletePerson, { personId })
      return httpClient.delete(requestString)
    },
    deletePersonFromProperty (phoneNumber, estateId) {
      const requestString = api.helpers.processRequestString(api.requestStrings.deletePersonFromProperty, {
        phoneNumber,
        estateId
      })
      return httpClient.delete(requestString)
    },
    deleteVehicleFromProperty (registrationNumber) {
      const requestString = api.helpers.processRequestString(api.requestStrings.deleteVehicleFromProperty, { registrationNumber })
      return httpClient.delete(requestString)
    },
    getCheckAlive () {
      return httpClient.get(api.helpers.processRequestString(api.requestStrings.getCheckAlive))
    },
    getEmptyingHistory (estateId, fromDate, toDate) {
      const requestString = api.helpers.processRequestString(api.requestStrings.getEmptyingHistory, {
        estateId,
        fromDate,
        toDate
      })
      return httpClient.get(requestString, appendCacheParam(true))
    },
    getEmptyingDates (estateId) {
      const requestString = api.helpers.processRequestString(api.requestStrings.getEmptyingDates, { estateId })
      return httpClient.get(requestString, appendCacheParam(true))
    },
    getEstatesPerCustomerDetails () {
      return httpClient.get(api.helpers.processRequestString(api.requestStrings.getEstatesPerCustomerDetails))
    },
    getEstatesPerCustomer (clearCacheEntry = false) {
      return httpClient.get(api.helpers.processRequestString(api.requestStrings.getEstatesPerCustomer), appendCacheParam(clearCacheEntry))
    },
    getOrganizations () {
      return httpClient.get(api.helpers.processRequestString(api.requestStrings.getOrganizations))
    },
    getQR (userId, clearCacheEntry = false) {
      const requestString = api.helpers.processRequestString(api.requestStrings.getQR, { userId })
      return httpClient.get(requestString, appendCacheParam(clearCacheEntry))
    },
    getQRCode (phoneNumber, registrationNumber) {
      const requestString = api.helpers.processRequestString(api.requestStrings.getQRCode, {
        phoneNumber,
        registrationNumber
      })
      return httpClient.get(requestString)
    },
    getQRCodeLink (phoneNumber) {
      const requestString = api.helpers.processRequestString(api.requestStrings.getQRCodeLink, { phoneNumber })
      return httpClient.get(requestString, appendCacheParam(true))
    },
    getQRCodesByPropertyId (estateId, clearCacheEntry = false) {
      const requestString = api.helpers.processRequestString(api.requestStrings.getQRCodesByPropertyId, { estateId })
      return httpClient.get(requestString, appendCacheParam(clearCacheEntry))
    },
    getUsageStatistics (estateId, fromDate, toDate) {
      const requestString = api.helpers.processRequestString(api.requestStrings.getUsageStatistics, {
        estateId,
        fromDate,
        toDate
      })
      return httpClient.get(requestString, appendCacheParam(true))
    },
    getVehiclesByPropertyId (estateId, clearCacheEntry = false) {
      const requestString = api.helpers.processRequestString(api.requestStrings.getVehiclesByPropertyId, { estateId })
      return httpClient.get(requestString, appendCacheParam(clearCacheEntry))
    },
    updateConsent (consentId) {
      return httpClient.put(api.helpers.processRequestString(api.requestStrings.updateConsent), {
        consentId
      })
    }
  },
  helpers: {
    processRequestString (str, args = {}) {
      args.apiFunctionPostfix = isOidcEnabled() ? '' : '_internal'
      for (const key in args) {
        const regex = new RegExp('{' + key + '}')
        str = str.replace(regex, args[key])
      }

      return str
    }
  }
}

export default {
  httpClient,
  api
}
