import { createApp } from 'vue'
import i18n from './i18n'
import App from './App.vue'
import './registerServiceWorker'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import store from './store'
import { vMaska } from 'maska/vue'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import './assets/css/sweetalert2.scss'
import './plugins/axios'
import './common/loaders'
import { createGtm } from '@gtm-support/vue-gtm'

const app = createApp(App)

const options = {
  showCancelButton: true,
  reverseButtons: true,
  padding: '2rem',
  width: '640px',
  confirmButtonColor: 'var(--color-dark-redish)',
  cancelButtonColor: 'var(--color-dark-greenish)',
  buttonsStyling: true,
  focusCancel: true
}

app.use(VueAxios, axios).use(store).use(router).use(i18n).directive('maska', vMaska).use(VueSweetalert2, options).mount('#app')

if (process.env.VUE_APP_GTM) {
  app.use(
    createGtm({ // See the documentation here: https://www.npmjs.com/package/@gtm-support/vue-gtm
      id: process.env.VUE_APP_GTM,
      debug: process.env.VUE_APP_DEBUG === 'true',
      vueRouter: router
    })
  )
}
