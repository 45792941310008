<template>
  <div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import store from '@/store'

export default {
  name: 'OidcCallback',
  methods: {
    ...mapActions('oidcStore', [
      'oidcSignInCallback'
    ])
  },
  created () {
    if (!this.$route.query.code) {
      this.$router.push('/logout/callback')
    }
    store.state.auth.code = this.$route.query.code
    this.oidcSignInCallback()
      .then((redirectPath) => {
        this.$router.push(redirectPath)
      })
      .catch((err) => {
        console.error(err)
        this.$router.push('/logout/callback')
      })
  }
}
</script>
