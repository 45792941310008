<template>
  <layout-full :class="this.$route.name" :breadcrumbs="breadcrumbs">
    <h1>{{ title }}</h1>
  </layout-full>
</template>

<script>
export default {
  name: 'AbstractView',
  data () {
    return {
      breadcrumbs: ['my-page']
    }
  },
  computed: {
    title () {
      return this.$i18n.t('routes.' + this.$route.name + '.title')
    }
  }
}
</script>
