import { createRouter, createWebHistory } from 'vue-router'
import { isOidcEnabled, isPwa } from '@/common/helpers'
import { nextTick } from 'vue'
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import LoginScreen from '@/views/LoginScreen.vue'
import OidcCallback from '@/views/OidcCallback'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'login-screen',
    component: LoginScreen,
    meta: {
      title: 'Login',
      isPublic: true
    }
  },
  {
    path: '/login/callback',
    name: 'oidcCallback',
    component: OidcCallback,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/logout/callback',
    name: 'logout-callback',
    redirect: {
      name: 'login-screen'
    }
  },
  {
    path: '/velg-eiendom',
    name: 'select-property',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "select-property" */ '../views/SelectProperty.vue'),
    meta: {
      title: 'Velg Eiendom',
      requiresUserPid: true
    }
  },
  {
    path: '/samtykke',
    name: 'terms-consent',
    component: () => import(/* webpackChunkName: "terms-consent" */ '../views/TermsConsent.vue'),
    meta: {
      title: 'Samtykke av brukervilkår'
    }
  },
  {
    path: '/min-side',
    name: 'my-page',
    component: () => import(/* webpackChunkName: "my-page" */ '../views/MyPage.vue'),
    meta: {
      title: 'Min Side',
      requiresSelectedPropertyID: true
    }
  },
  {
    path: '/send-kode-pa-nytt',
    name: 'resend-qrcode',
    component: () => import(/* webpackChunkName: "resend-qrcode" */ '../views/QRCodeResending.vue'),
    meta: {
      title: 'Manger du koden?',
      isPublic: true
    }
  },
  {
    path: '/getQR/:pid',
    name: 'open-qrcode',
    component: () => import(/* webpackChunkName: "open-qrcode" */ '../views/QRCodeDisplay.vue'),
    meta: {
      title: 'Dine QR-koder',
      isPublic: true
    }
  },
  {
    path: '/tommehistorikk',
    name: 'collection-history',
    component: () => import(/* webpackChunkName: "collection-history" */ '../views/CollectionHistory.vue'),
    meta: {
      title: 'Tømmehistorikk',
      requiresSelectedPropertyID: true
    }
  },
  {
    path: '/error',
    name: 'error-page',
    component: () => import(/* webpackChunkName: "error-page" */ '../views/ErrorPage.vue'),
    meta: {
      title: 'Feil'
    }
  },
  {
    path: '/gjenvinning/gjenvinningsstasjon-qr-kode',
    name: 'recycling-station-qr-code',
    component: () => import(/* webpackChunkName: "recycling-station-qr-code" */ '../views/StationQRCode.vue'),
    meta: {
      title: 'Visning av QR kode',
      category: 'Gjenvinning',
      requiresSelectedPropertyID: true
    }
  },
  {
    path: '/gjenvinning/administrer-tilgang',
    name: 'recycling-admin-users',
    component: () => import(/* webpackChunkName: "recycling-admin-users" */ '../views/AdminUsers.vue'),
    meta: {
      title: 'Administrering av tillate personer',
      category: 'Gjenvinning',
      requiresSelectedPropertyID: true
    }
  },
  {
    path: '/gjenvinning/administrer-kjoretoy',
    name: 'recycling-admin-vehicles',
    component: () => import(/* webpackChunkName: "recycling-admin-vehicles" */ '../views/AdminVehicles.vue'),
    meta: {
      title: 'Administrering av tillate kjøretøy',
      category: 'Gjenvinning',
      requiresSelectedPropertyID: true
    }
  },
  {
    path: '/gjenvinning/besoksoversikt',
    name: 'recycling-visits',
    component: () => import(/* webpackChunkName: "recycling-visits" */ '../views/VisitOverview.vue'),
    meta: {
      title: 'Besøksoversikt',
      category: 'Gjenvinning',
      requiresSelectedPropertyID: true
    }
  },
  {
    path: '/tomming',
    name: 'collection-dates',
    component: () => import(/* webpackChunkName: "collection-dates" */ '../views/CollectionDates.vue'),
    meta: {
      title: 'Tømming',
      category: 'Tømming',
      requiresSelectedPropertyID: true
    }
  },
  {
    path: '/consent/:consentId',
    name: 'property-consent',
    component: () => import(/* webpackChunkName: "open-qrcode" */ '../views/PropertyConsent.vue'),
    meta: {
      title: 'Personvern i Avfall Sør',
      isPublic: true
    }
  },
  {
    path: '/:catchAll(.*)*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/ErrorPage.vue'),
    meta: {
      title: 'Ikke funnet'
    }
  }
]

if (isOidcEnabled()) {
  routes.push({
    path: '/debug',
    name: 'debug-page',
    component: () => import(/* webpackChunkName: "debug-page" */ '../views/DebugPage.vue'),
    meta: {
      title: 'Debug'
    }
  })
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      // Go to saved position if it is set.
      return savedPosition
    }

    // Always scroll to top.
    return { top: 0 }
  }
})

// Allow access to the protected route if the user has been logged in within the last 30 days and they use PWA.
router.beforeEach((to) => {
  if (!to.meta.isPublic) {
    to.meta.requiresTermsOfUseConsent = true // It replaces the 'isPublic' meta, which is being used for a different purpose than originally intended. See the workaround below.
  }
  if (!store.state.auth.userExpiresAt || !isPwa()) {
    return
  }
  const now = Math.floor(Date.now() / 1000) // Timestamp without microseconds.
  if (now < store.state.auth.userExpiresAt + process.env.VUE_APP_CACHE_EXPIRY_TIME) {
    to.meta.isPublic = true // Workaroud to bypass OIDC authentication.
  }
})

if (isOidcEnabled()) {
  router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'))
}

// TODO find cleaner way to handle consent, nested ifs
router.beforeEach((to) => {
  if (to.meta.requiresUserPid && !store.state.auth.userPid) {
    return '/'
  }
  // Redirect to the 'select property' page if no property has been selected yet, but its ID is required.
  if (to.meta.requiresSelectedPropertyID && !store.state.property.selectedPropertyID) {
    return { name: 'select-property' }
  }
  // Allow access to routes that do not require consent acceptance.
  // In this case, it will be all public routes.
  if (!to.meta.requiresTermsOfUseConsent) {
    return
  }
  const isConsentRoute = to.name === 'terms-consent'
  if (!store.state.consent.termsOfUse && isOidcEnabled()) {
    // Prevent consent route loop
    if (isConsentRoute) {
      return // Prevents calling the "next" callback more than once in one navigation guard.
    }
    return { name: 'terms-consent' }
  }
  // Deny access to the consent route once consent has been accepted.
  if (isConsentRoute) {
    return { name: 'select-property' }
  }
})

router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    document.title = to.meta.title + ' - ' + process.env.VUE_APP_NAME || process.env.VUE_APP_NAME
  })
})

export default router
