import { api } from '@/plugins/api'
import * as types from '../mutation-types'

export const namespaced = true

// state
export const state = () => ({
  organizations: [],
  organizationsMoved: [],
  organizationsLoad: false,
  organizationsLoaded: false,
  selectedEntityNumber: ''
})

// getters
export const getters = {
  organizations: state => state.organizations,
  organizationsMoved: state => state.organizationsMoved,
  organizationsLoad: state => state.organizationsLoad,
  organizationsLoaded: state => state.organizationsLoaded,
  selectedEntityNumber: state => state.selectedEntityNumber
}

// mutations
export const mutations = {
  [types.ORGANIZATIONS_CLEAR] (state) {
    state.organizations = []
    state.organizationsMoved = []
    state.organizationsLoad = false
    state.organizationsLoaded = false
  },
  [types.ORGANIZATION_MOVE_FAILURE] (state, { index, entityNumber }) {
    for (const i in state.organizations) {
      if (state.organizations[i].entityNumber === entityNumber) {
        state.organizations[i].isMoving = false
        state.organizations[i].isError = true
        state.organizations[i].errorMessageKey = 'error.message.response-failure'
      }
    }
    this.dispatch('organization/selectOrganization', { entityNumber: '' })
  },
  [types.ORGANIZATION_MOVE_SUCCESS] (state, { properties, index, entityNumber }) {
    state.organizations[index].isMoving = false // Enable loading indicator
    if (properties.length) {
      let isPropertyUnique = true
      // Find out if the selected property already exists on the property list
      this.state.property.properties.forEach((element) => {
        if (properties.some(property => property.EstateId === element.id)) {
          state.organizations[index].isError = true
          state.organizations[index].errorMessageKey = 'component.organization-list.altinn-error-already-exists'
          isPropertyUnique = false
        }
      })
      // Add the selected property to the list only if it does not exist there yet
      if (isPropertyUnique) {
        let selectedPropertyID = ''
        properties.forEach(property => {
          state.organizationsMoved.push({
            id: property.EstateId,
            title: property.Adress + ', ' + property.Municipality,
            owner: property.Owner,
            entityNumber
          })
          selectedPropertyID = property.EstateId
        })
        state.organizations.splice(index, 1) // Remove the selected organization from the organization list
        this.state.property.properties = this.state.property.properties.concat(state.organizationsMoved) // Append the selected organization to the property list
        this.dispatch('property/selectProperty', { propertyId: selectedPropertyID })
      }
    } else {
      state.organizations[index].isError = true
      state.organizations[index].errorMessageKey = 'component.organization-list.altinn-error-no-relationship'
    }
    this.dispatch('organization/selectOrganization', { entityNumber: '' })
  },
  [types.ORGANIZATION_MOVING] (state, { entityNumber }) {
    this.dispatch('organization/selectOrganization', { entityNumber })
    for (const i in state.organizations) {
      if (state.organizations[i].entityNumber === entityNumber) {
        state.organizations[i].isError = false
        state.organizations[i].isMoving = true
      }
    }
  },
  [types.ORGANIZATIONS_FETCH_FAILURE] (state) {
    state.organizations = []
    state.organizationsLoaded = true
  },
  [types.ORGANIZATIONS_FETCH_SUCCESS] (state, { organizations }) {
    state.organizations = organizations.map(organization => {
      return {
        name: organization.Name,
        entityNumber: organization.delegatedId,
        role: organization.Role,
        isMoving: false,
        isError: false
      }
    })
    state.organizationsLoaded = true
  },
  [types.ORGANIZATIONS_LOAD] (state) {
    state.organizationsLoad = true
  },
  [types.ORGANIZATION_SELECT] (state, { entityNumber }) {
    state.selectedEntityNumber = entityNumber
  }
}

// actions
export const actions = {
  clear ({ commit }) {
    commit(types.ORGANIZATIONS_CLEAR)
  },
  getOrganizations ({ commit }) {
    commit(types.ORGANIZATIONS_LOAD)
    return new Promise((resolve, reject) => {
      api.requests.getOrganizations().then((res) => {
        commit(types.ORGANIZATIONS_FETCH_SUCCESS, { organizations: res.data })
        resolve()
      }).catch(() => {
        commit(types.ORGANIZATIONS_FETCH_FAILURE, { organizations: [] })
        resolve()
      })
    })
  },
  moveOrganization ({ commit }, { index, entityNumber }) {
    commit(types.ORGANIZATION_MOVING, { entityNumber })
    return new Promise((resolve, reject) => {
      api.requests.getEstatesPerCustomer(true).then((res) => {
        commit(types.ORGANIZATION_MOVE_SUCCESS, { properties: res.data, index, entityNumber })
        resolve()
      }).catch(() => {
        commit(types.ORGANIZATION_MOVE_FAILURE, { index, entityNumber })
        resolve()
      })
    })
  },
  selectOrganization ({ commit }, { entityNumber }) {
    commit(types.ORGANIZATION_SELECT, { entityNumber })
  }
}
