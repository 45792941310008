<template>
  <LayoutFull
    :class="this.$route.name"
    :breadcrumbs="breadcrumbs"
    :showPropertyInfo="showPropertyInfo"
    :showLogout="showLogout"
    :showHeaderBottomBar="false"
  >
    <LoginButton/>
  </LayoutFull>
</template>

<script>
import AbstractView from '@/views/AbstractView'
import LayoutFull from '@/layouts/LayoutFull'
import LoginButton from '@/components/LoginButton.vue'

export default {
  name: 'LoginScreen',
  extends: AbstractView,
  data () {
    return {
      breadcrumbs: false,
      showLogout: false,
      showPropertyInfo: false
    }
  },
  components: {
    LayoutFull,
    LoginButton
  }
}
</script>

<style lang="scss" scoped>
:deep(.footer) {
  margin-top: 0;
}
</style>
