import * as types from '@/store/mutation-types'
import { api } from '@/plugins/api'

export const namespaced = true

// state
export const state = () => ({
  userList: [],
  userListLoaded: false,
  userListRaw: []
})

// getters
export const getters = {
  userListLoaded: state => state.userListLoaded,
  userList: state => state.userList,
  userListRaw: state => state.userListRaw,
  activeUserList: state => state.userList.filter(user => user.active === true),
  pendingUserList: state => state.userList.filter(user => user.pending === true)
}

// mutations
export const mutations = {
  [types.USERS_LOADING] (state) {
    state.userList = []
    state.userListLoaded = false
    state.userListRaw = []
  },
  [types.FETCH_USERS_SUCCESS] (state, { users }) {
    state.userListLoaded = false
    state.userListRaw = users

    if (users.length !== 0) {
      users.forEach((user) => {
        // Do not list property owners.
        if (!user.phoneNumber) {
          return
        }

        const item = {
          key: user.phoneNumber,
          title: user.firstName,
          subTitle: user.phoneNumber,
          isDeleting: false,
          isUpdating: false,
          active: false,
          pending: true
        }

        if (user.consented && user.validity === 'valid') { // Other possible validity values: awaiting, invalid.
          item.active = true
          item.pending = false
        }

        state.userList.push(item)
      })
    }
    state.userListLoaded = true
  },
  [types.FETCH_USERS_FAILURE] (state) {
    state.userList = []
    state.userListLoaded = true
    state.userListRaw = []
  },
  [types.DELETE_USER] (state, { phoneNumber }) {
    for (const i in state.userList) {
      if (state.userList[i].key === phoneNumber) {
        state.userList[i].isDeleting = true
      }
    }
  },
  [types.UPDATE_USER] (state, { phoneNumber, payload }) {
    for (const i in state.userList) {
      if (state.userList[i].key === phoneNumber) {
        state.userList[i] = {
          ...state.userList[i],
          ...payload
        }
      }
    }
  }
}

// actions
export const actions = {
  deleteUser ({ commit }, { phoneNumber }) {
    commit(types.DELETE_USER, { phoneNumber })
    return new Promise((resolve, reject) => {
      api.requests.deletePersonFromProperty(phoneNumber, this.state.property.selectedPropertyID)
        .then((res) => {
          resolve()
        })
        .catch(() => {
          reject(new Error('fail'))
        })
    })
  },
  updateUser ({ commit }, { phoneNumber, payload }) {
    commit(types.UPDATE_USER, { phoneNumber, payload })
  },
  getUsers ({ commit }, { clearCacheEntry }) {
    commit(types.USERS_LOADING)
    return new Promise((resolve, reject) => {
      api.requests.getQRCodesByPropertyId(this.state.property.selectedPropertyID, clearCacheEntry).then((res) => {
        commit(types.FETCH_USERS_SUCCESS, { users: res.data })
        resolve()
      }).catch((res) => {
        commit(types.FETCH_USERS_FAILURE, { users: {} }) // TODO Add error handling
        reject(new Error('fail'))
      })
    })
  }
}
