<template>
  <footer class="footer">
    <div class="bar widget-bar wrapper">
      <div class="container widgets">
        <div class="widget widget-1">
          <img class="logo" src="../../assets/svgs/avfallsor-logo-compact.svg" alt="Avfall Sør Logo" decoding="async" loading="lazy">
        </div>
        <div class="widget widget-2">
          <h2 class="strong-text">{{ $t('global.texts.widget-2-heading') }}</h2>
          <div class="widget-content" v-html="$t('global.texts.widget-2')"></div>
        </div>
        <div class="widget widget-3">
          <h2 class="strong-text">{{ $t('global.texts.widget-3-heading') }}</h2>
          <div class="widget-content" v-html="$t('global.texts.widget-3')"></div>
        </div>
        <div class="widget widget-4">
          <h2 class="strong-text">{{ $t('global.texts.widget-4-heading') }}</h2>
          <div class="widget-content" v-html="$t('global.texts.widget-4')"></div>
        </div>
        <div class="widget widget-5">
          <h2 class="strong-text">{{ $t('global.texts.widget-5-heading') }}</h2>
          <div class="social-icons">
            <a
              href="https://www.facebook.com/AvfallSor"
              target="_blank"
              rel="noopener"
            ><img src="../../assets/svgs/AS_social-media_facebook.svg" alt="Facebook" decoding="async" loading="lazy"></a>
            <a
              href="https://www.instagram.com/avfallsor/"
              target="_blank"
              rel="noopener"
            ><img src="../../assets/svgs/AS_social-media_instagram.svg" alt="Instagram" decoding="async" loading="lazy"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="bar bottom-bar wrapper">
      <div class="container">
        <div class="left">
          <span v-html="$t('global.texts.copyright', { appVersion })"></span>
        </div>
        <div class="right">
          <span v-html="$t('global.texts.privacy-policy')"></span>&#124;<span v-html="$t('global.texts.terms-of-use')"></span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
const appVersion = require('../../../package.json').version
</script>

<style lang="scss" scoped>
.widget {
  margin-bottom: 2rem;
}
.footer {
  margin-top: 1.5rem;

  @media screen and (min-width: 1024px) {
    margin-top: 3rem;
    .bar .container {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
    }
  }

  .bar .container .right {
    display: flex;
    column-gap: 1em;
  }
}
</style>
